// https://github.com/storyblok/storyblok-nuxt/blob/main/src/runtime/composables/useAsyncStoryblok.ts

import { useStoryblokApi, useStoryblokBridge } from "@storyblok/vue";
import { useState } from "nuxt/app";
import { onMounted } from "vue";

export const useCustomAsyncStoryblok = async (
  url = "",
  apiOptions = {},
  bridgeOptions = {}
) => {
  const uniqueKey = `${JSON.stringify(apiOptions)}${url}`;
  const story = useState(`${uniqueKey}-state`);
  const storyblokApiInstance = useStoryblokApi();

  onMounted(() => {
    if (story.value && story.value.id) {
      useStoryblokBridge(
        story.value.id,
        evStory => (story.value = evStory),
        bridgeOptions,
      );
    }
  });

  if (!story.value) {
    const path =
      url.includes("cdn/") ? url : `cdn/stories${url ? "/" + url : ""}`;
    const result = await storyblokApiInstance.get(path, apiOptions).then((response) => {
      return response;
    }).catch((error) => {
      console.log(error);
    });
    
    story.value = result.data;
    story.headers = result.headers;
  }

  return story;
};
